/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Text, Heading, Link} from "@chakra-ui/react";
import {StackIconsGroup} from "components";
import {ExternalLinkIcon} from "@chakra-ui/icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/featured/simple-math.png",
    alt: "imgOne:"
  })), "\n", React.createElement(_components.h2, null, "Summary"), "\n", React.createElement(Heading, {
    as: "div",
    variant: "sec",
    size: "md"
  }, React.createElement(_components.p, null, "I realized I don't have many projects on my portfolio that are \"really\" functionality focused. So I decided to build a little speed math game. The project uses TypeScript, Next.js, and Chakra. I had some interesting challenges associated with state management and was able to dive into solutions such as Jotai, React-racker and looked at Context as a solution.")), "\n", React.createElement(Link, {
    mb: 6,
    asExternal: true,
    variant: "linkOne",
    href: props.pageContext.frontmatter.link,
    target: "_blank"
  }, props.pageContext.frontmatter.title, React.createElement(ExternalLinkIcon, {
    mx: "2px"
  })), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, "Stack"), "\n", React.createElement(StackIconsGroup, {
    iconArr: ["chakraui", "gatsby", "netlify", "github", "mdx"]
  }), "\n", React.createElement(_components.h3, null, "Objective"), "\n", React.createElement(_components.p, null, "Solve an array of math facts of various levels of difficulty before the timer runs out."), "\n", React.createElement(_components.h4, null, "Features Coverage"), "\n", React.createElement(_components.p, null, "From a feature standpoint,"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[x] 3 levels of difficulty for each operation"), "\n", React.createElement(_components.li, null, "[x] time problems"), "\n", React.createElement(_components.li, null, "[x] lives/tries"), "\n", React.createElement(_components.li, null, "[x] dark/light mode"), "\n", React.createElement(_components.li, null, "[x] clean mobile play"), "\n", React.createElement(_components.li, null, "[x] score and local storage support"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/projects/simplemath/simplemath-1.gif",
    alt: "simple math tour"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
